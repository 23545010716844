@font-face {
	font-family: 'Mont';
	src: local('Mont Regular'), local('Mont-Regular'),
		url('Mont-Regular.woff2') format('woff2'),
		url('Mont-Regular.woff') format('woff'),
		url('Mont-Regular.ttf') format('truetype'),
		url('Mont-Regular.eot?#iefix') format('embedded-opentype'),
		url('Mont-Regular.eot') format('embedded-opentype');
	font-weight: 400;
	font-style: normal;
}
@font-face {
	font-family: 'Mont';

	src: local('Mont SemiBold'), local('Mont-SemiBold'),
		url('Mont-SemiBold.woff2') format('woff2'),
		url('Mont-SemiBold.woff') format('woff'),
		url('Mont-SemiBold.ttf') format('truetype'),
		url('Mont-SemiBold.eot?#iefix') format('embedded-opentype'),
		url('Mont-SemiBold.eot') format('embedded-opentype');
	font-weight: 600;
	font-style: normal;
}
@font-face {
	font-family: 'Mont';
	src: local('Mont Bold'), local('Mont-Bold'),
		url('Mont-Bold.woff2') format('woff2'),
		url('Mont-Bold.woff') format('woff'),
		url('Mont-Bold.ttf') format('truetype'),
		url('Mont-Bold.eot?#iefix') format('embedded-opentype'),
		url('Mont-Bold.eot') format('embedded-opentype');
	font-weight: bold;
	font-style: normal;
}

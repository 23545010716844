@import url('../../tools/normalize.min.css');
@import url('../../public/fonts/fonts.css');

:root {
	--font-main: 'Mont', Arial, Helvetica, sans-serif;
	--color-black: #393939;
	--color-dark: #282828;
	--color-white: #ffffff;
	--color-orange: #ff862d;
}
* {
	-webkit-box-sizing: border-box;
	box-sizing: border-box;

	&::after {
		-webkit-box-sizing: border-box;
		box-sizing: border-box;

	}
	&::before {
		-webkit-box-sizing: border-box;
		box-sizing: border-box;

	}
}
a {
	text-decoration: none !important;
	color: inherit;
	cursor: pointer;
}
html {
	background-color: var(--color-white);
	cursor: auto;
}
body {
	font-family: var(--font-main);
	font-style: normal;
	font-weight: 600;
	color: var(--color-black);
	animation: fade 0.4s ease-in-out;
}
button {
	padding: 0;
	border: none;
	font: inherit;
	color: inherit;
	background-color: transparent;
	cursor: pointer;

	&:focus {
		outline: none;
	}
}
img {
	max-width: 100%;
	display: block;
}
ul {
	list-style-type: none;
	margin: 0;
	padding: 0;
}
h1,
h2,
h3,
h4,
p {
	margin: 0;
	padding: 0;
}
sup {
	font-family: var(--font-main);
	font-style: normal;
	font-weight: 600;
	font-size: 16px;
	line-height: 16px;
	color: var(--color-orange);
}
h1,
h2 {
	font-weight: 600;
	font-size: 150px;
	line-height: 150px;
	color: var(--color-black);
}
h3 {
	font-weight: 600;
	font-size: 78px;
	line-height: 88px;
	color: var(--color-black);
}

.fade {
	-webkit-animation-name: fade;
	animation-name: fade;
	-webkit-animation-duration: 0.7s;
	animation-duration: 0.7s;
	-webkit-animation-name: fade;
	-webkit-animation-duration: 0.7s;
}
@-webkit-keyframes fade {
	from {
		opacity: 0;
	}
	to {
		opacity: 1;
	}
}
@keyframes fade {
	from {
		opacity: 0;
	}
	to {
		opacity: 1;
	}
}
.circle_rotate {
	animation-name: circle_rotate;
}
@-webkit-keyframes circle_rotate {
	0% {
		transform: rotate(0deg);
	}
	100% {
		transform: rotate(180deg);
	}
}
@keyframes circle_rotate {
	0% {
		transform: rotate(0deg);
	}
	100% {
		transform: rotate(180deg);
	}
}
.circle_rotate {
	animation-name: circle_rotate_reverse;
}
@keyframes circle_rotate_reverse {
	0% {
		transform: rotate(180deg);
	}
	100% {
		transform: rotate(0deg);
	}
}
@-webkit-keyframes circle_rotate_reverse {
	0% {
		transform: rotate(180deg);
	}
	100% {
		transform: rotate(0deg);
	}
}
.main_wrapper {
	overflow: hidden;
}
.container {
	background: var(--color-white);
	min-height: 100%;
	width: 100%;
	max-width: 1320px;
	margin: 0 auto;
	padding: 0 20px;
	padding-bottom: clamp(64px, 15.625vw, 200px);
}
.hidden {
	display: none;
	opacity: 0;
}
.non-visible {
	visibility: hidden;
	animation: fade 0.4s linear;
}
.overflow-hidden {
	overflow-y: hidden;
}
.mob-scroll {
	overflow-x: scroll;
}
.hovered {
	animation: circle_rotate 1s ease-out 1 normal forwards;
}
.hovered-reverse {
	animation: circle_rotate_reverse 1s ease-out 1 alternate forwards;
}
#customCursor {
	position: fixed;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	background-image: url('../../public/images/cursor.png');
	background-position: center;
	background-size: 100%;
	background-repeat: no-repeat;
	width: 400px;
	height: 400px;
	pointer-events: none;
	z-index: 50;
	transition-property: left, top;
	transition-duration: 1.2s, 1.2s;
	transition-timing-function: cubic-bezier(0.1, 0.69, 0.36, 0.98);
}

.header {
	position: fixed;
	background-color: var(--color-white);
	max-width: 100vw;
	left: 0;
	top: 0;
	min-height: clamp(64px, 6.8vw, 88px);
	min-height: 88px;
	width: 100%;
	z-index: 20;
	&_wrapper {
		display: flex;
		align-items: flex-start;
		justify-content: space-between;
		max-width: 1320px;
		min-height: clamp(64px, 6.8vw, 88px);
		min-height: 88px;
		margin: 0 auto;
		padding: 0 20px;
	}
	&__logo {
		width: 95%;
		margin-top: clamp(6px, 1.56vw, 20px);
		margin-top: 20px;
		display: block;
		position: relative;
		text-align: left;
		transition: transform 0.5s cubic-bezier(0.1, 0.69, 0.36, 0.98);
		&_text {
			font-weight: 600;
			font-size: clamp(10px, 1.18vw, 15px);
			line-height: clamp(18px, 2.1vw, 24px);
			line-height: 160%;
			max-width: 400px;
			max-width: clamp(220px, 34vw, 400px);
		}
		&_img {
			position: absolute;
			margin-top: 80px;
			margin-left: 0;
			left: 0;
			transform-origin: 0px 0px;
			transition: transform 0.5s cubic-bezier(0.1, 0.69, 0.36, 0.98);
			img {
				max-width: clamp(80%, 95vw, 110%);
				margin-left: 0;
				text-align: left;
			}
		}
	}
	&__logo-mob {
		display: none;
	}
	&__menu {
		width: 5%;
		margin-top: clamp(6px, 1.3vw, 20px);
		margin-top: 20px;
		display: flex;
		align-items: center;
		justify-content: flex-end;
		&_burger {
			z-index: 1000;
			display: block;
			position: relative;
			margin-top: 5px;
			width: 40px;
			height: 40px;
			cursor: pointer;
			&:after,
			&:before,
			span {
				height: 2px;
				width: 100%;
				position: absolute;
				background: var(--color-black);
				margin: 0 auto;
			}
			span {
				top: 19px;
			}
			&:after,
			&:before {
				content: '';
			}
			&:after {
				bottom: 7px;
			}
			&:before {
				top: 7px;
			}
		}
		&_burger.open-menu {
			z-index: 1000;
			span {
				transform: scaleX(0);
				transition: 0.4s;
			}
			&:before {
				transform: rotate(45deg);
				top: 19px;
				background: var(--color-white);
				transition: 0.6s;
			}
			&:after {
				transform: rotate(-45deg);
				bottom: 19px;
				background: var(--color-white);
				transition: 0.6s;
			}
		}
		&_burger.close-menu {
			z-index: 1000;
			span {
				transform: scaleX(100%);
				transition: 0.4s;
			}
			&:before {
				transform: rotate(0deg);
				top: 7px;
				background: var(--color-black);
				transition: 0.6s;
			}
			&:after {
				transform: rotate(0deg);
				bottom: 7px;
				background: var(--color-black);
				transition: 0.6s;
			}
		}
		&_nav {
			transition: opacity 0.3s;
			display: none;
			position: absolute;
			background-color: var(--color-black);
			width: 100vw;
			height: 100vh;
			top: 0;
			right: 0;
			.nav_wrap {
				margin: 0 auto;
				margin-top: 32px;
				max-width: 1280px;
				width: 100%;
			}
			.logo {
				max-width: calc(1280px - 10px);
				width: 100%;
				margin-left: -5px;
			}
			.menu {
				display: flex;
				flex-direction: column;
				justify-content: center;
				align-items: flex-start;
				margin: 0 auto;
				max-width: 620px;
				width: 100%;
				height: calc(100vh - (46px + 29px));
				color: var(--color-white);
				li {
					margin-bottom: 40px;
					&:last-child {
						margin-bottom: 0;
					}
				}
				&__item {
					display: flex;
					justify-content: center;
					align-items: flex-start;
					.item_text {
						font-weight: 600;
						font-size: clamp(46px, 5.6vw, 78px);
						line-height: 112%;
						color: var(--color-white);
					}
					.item_number {
						margin-left: 10px;
						margin-top: clamp(2px, 0.73vw, 10px);
						font-weight: 600;
						font-size: 16px;
						line-height: 100%;
						color: var(--color-orange);
					}
				}
			}
		}
		&_nav.open-menu {
			display: block;
			transition: opacity 0.3s;
			animation: fade 0.3s linear;
			padding: 0 20px;
			overflow: hidden;
			position: fixed;
			z-index: 10;
		}
		&_nav.close-menu {
			transition: opacity 0.3s;
			animation: fade 0.3s linear;
			padding: 0 20px;
			overflow: visible;
			position: fixed;
			z-index: 10;
		}
	}
}
.disable-scroll {
	overflow: hidden;
	height: 100vh;
	width: 100%;
	position: fixed;
	left: 0;
	top: 0;
}
.main {
	._1 {
		.intro {
			margin-top: 164px;
			display: flex;
			flex-direction: column;
			align-items: flex-start;
			justify-content: center;
			max-width: 1280px;
			&__logo_img {
				opacity: 0;
				visibility: hidden;
			}
			&__title {
				margin-top: clamp(24px, 6.25vw, 80px);
				h1 {
					font-weight: 600;
					font-size: clamp(46px, 11.72vw, 150px);
					line-height: 100%;
				}
			}
			&__mob-subtitle {
				display: none;
			}
			&__box {
				margin-top: clamp(24px, 3.125vw, 40px);
				display: flex;
				align-items: center;
				justify-content: space-between;
				width: 100%;
				.description {
					max-width: 840px;
					margin-right: clamp(20px, 3.125vw, 40px);
					p {
						font-weight: 600;
						font-size: clamp(22px, 2.5vw, 32px);
						line-height: 138%;
						span {
							mark {
								background-color: var(--color-orange);
								color: var(--color-white);
								padding: 0 0.1em;
								border: 2px solid var(--color-orange);
							}
						}
					}
				}
				.mob-calc {
					display: none;
				}
				.calc {
					position: relative;
					display: block;
					max-width: clamp(120px, 20.32vw, 260px);
					width: 100%;
					margin-right: clamp(20px, 4.84vw, 62px);
					color: transparent;
					text-decoration: none;
					cursor: pointer;
					.calc-round {
						background-image: url('../../public/images/circle01.svg');
						background-position: center;
						background-size: 100%;
						background-repeat: no-repeat;
						min-width: clamp(120px, 20.32vw, 260px);
						min-height: clamp(120px, 20.32vw, 260px);
						transition: transform 0.8s
							cubic-bezier(0.4, 0.7, 0.3, 1);
					}
					.calc-text {
						position: absolute;
						top: 50%;
						left: 50%;
						transform: translate(-50%, -50%);
						p {
							font-weight: 600;
							font-size: clamp(14px, 1.72vw, 22px);
							line-height: 127%;
							max-width: 132px;
							color: var(--color-black);
						}
					}
					.calc-arrow {
						position: absolute;
						top: clamp(46%, 10.156vw, 50%);
						transform: translateY(
							calc(-50% + clamp(4px, 1.24vw, 16px))
						);
						right: clamp(-61px, -4.765vw, -20px);
						img {
							max-width: clamp(70px, 11.33vw, 145px);
						}
					}
				}
			}
		}
	}
	._2 {
		.team {
			margin-top: clamp(80px, 15.625vw, 200px);
			&__title {
				width: 100%;
				position: relative;
				height: clamp(70px, 18.75vw, 240px);
				transition: transform 0.8s cubic-bezier(0.1, 0.69, 0.36, 0.98);
				.title_wrap {
					width: calc(
						clamp(680px, 147.66vw, 1890px) +
							clamp(66px, 18.75vw, 240px)
					);
					position: absolute;
					right: calc(
						clamp(-720px, -147.66vw, -2090px) -
							clamp(66px, 18.75vw, 240px)
					);
					display: flex;
					flex-wrap: nowrap;
					justify-content: center;
					align-items: flex-start;
					img {
						margin-right: clamp(16px, 6.25vw, 80px);
						margin-top: clamp(4px, 1.875vw, 24px);
						transition: transform 0.8s
							cubic-bezier(0.1, 0.69, 0.36, 0.98);
						max-width: clamp(40px, 12.5vw, 160px);
					}
					h2 {
						font-weight: 600;
						font-size: clamp(66px, 18.75vw, 240px);
						line-height: 100%;
						width: clamp(680px, 147.66vw, 1890px);
						white-space: nowrap;
					}
				}
			}
			&__box {
				margin-top: clamp(32px, 6.25vw, 80px);
				&_item {
					position: relative;
					.expand_btn {
						display: flex;
						align-items: center;
						justify-content: space-between;
						margin-top: 48px;
						cursor: pointer;
						&_text {
							font-weight: 600;
							font-size: clamp(30px, 6.094vw, 78px);
							line-height: 112%;
							cursor: pointer;
						}
						&_icon {
							max-width: clamp(28px, 4.375vw, 56px);
							max-height: clamp(28px, 4.375vw, 56px);
							width: 100%;
							height: 100%;
							display: block;
							position: relative;
							cursor: pointer;
							.minus {
								height: 2px;
								width: 100%;
								position: absolute;
								background: var(--color-orange);
								margin: 0 auto;
							}
							&:after {
								position: absolute;
								content: '';
								height: clamp(28px, 4.375vw, 56px);
								width: 2px;
								background: var(--color-orange);
								left: clamp(13.5px, 2.109375vw, 27px);
								top: clamp(-27px, -2.109375vw, -13.5px);
								transform: scaleY(100%);
								transition: 0.6s;
							}
						}
						&_icon.pinch {
							&:after {
								transform: scaleY(0);
								transition: 0.6s;
							}
						}
					}
					.contentmakers_btn {
						margin-top: 0;
					}
					.expandable_title {
						max-width: 1280px;
						max-height: 0;
						overflow: hidden;
						margin: 0 auto;
						transition: max-height 0.6s ease-out;
						p {
							margin-top: clamp(16px, 1.5625vw, 20px);
							font-weight: 600;
							font-size: clamp(14px, 1.72vw, 22px);
							line-height: 155%;
							color: var(--color-dark);
						}
					}
					.expandable_box {
						max-height: 0;
						max-width: 1280px;
						margin: 0 auto;
						overflow: hidden;
						transition: max-height 0.5s ease-in;
						margin-bottom: 24px;
						.wrapper {
							margin-top: clamp(18px, 3.125vw, 40px);
							width: 100%;
							.marquee_body {
								width: 100%;
								.marquee,
								.marquee_reverse {
									width: 100%;
									position: relative;
									min-height: clamp(20px, 5vw, 64px);
									transition: transform 1.2s
										cubic-bezier(0.1, 0.69, 0.36, 0.98);
									.marquee_inner {
										position: absolute;
										display: inline-flex;
										white-space: nowrap;
										transform: translateX(-1500px);
										.service_keywords {
											display: inline-flex;
											li {
												width: 100%;
												font-weight: 800;
												font-size: clamp(
													16px,
													3.75vw,
													48px
												);
												line-height: 130%;
												letter-spacing: 0.05em;
												text-transform: uppercase;
												color: var(--color-black);
												margin: 0
													clamp(12px, 1.5625vw, 20px);
												&:not(:first-child) {
													color: transparent;
													opacity: 0.6;
													-webkit-text-stroke: 1px
														var(--color-black);
												}
											}
										}
									}
								}
								.marquee_reverse {
									.marquee_inner {
										transform: translateX(-2000px);
									}
								}
							}
						}
					}
					&:after {
						position: absolute;
						content: ' ';
						width: 100%;
						height: 2px;
						background-color: #e4e4e4;
					}
				}
			}
		}
	}
	._3 {
		.services {
			margin-top: clamp(100px, 15.625vw, 200px);
			&__header {
				width: 100%;
				h2 {
					font-weight: 600;
					font-size: clamp(66px, 11.72vw, 150px);
					line-height: 100%;
				}
			}
			&__box {
				margin: 0 auto;
				margin-right: 0;
				margin-top: clamp(32px, 6.4vw, 80px);
				max-width: 840px;
				overflow: hidden;
				&_title {
					overflow: hidden;
					max-width: 840px;
					p {
						font-weight: 600;
						font-size: clamp(22px, 2.5vw, 32px);
						line-height: 136%;
						color: var(--color-dark);
					}
				}
				&_description {
					overflow: hidden;
					max-width: 400px;
					margin-top: clamp(16px, 3.125vw, 40px);
					p {
						font-weight: 600;
						font-size: clamp(14px, 1.172vw, 15px);
						line-height: 160%;
					}
				}
				&_wrapper {
					display: flex;
					flex-direction: column;
					align-items: flex-start;
					justify-content: center;
					.box_item {
						max-width: 840px;
						width: 100%;
						overflow: hidden;
						.box_item_wrap {
							display: flex;
							justify-content: flex-start;
							align-items: center;
							max-width: 840px;
							transition: transform 1.2s
								cubic-bezier(0.1, 0.69, 0.36, 0.98);
							.item {
								flex-shrink: 0;
								max-width: clamp(220px, 34.375vw, 440px);
								width: 100%;
								p {
									font-weight: 600;
									font-size: clamp(14px, 1.72vw, 22px);
									line-height: 160%;
									color: var(--color-dark);
								}
							}
							.item2 {
								max-width: 346px;
								width: 100%;
								img {
									max-width: clamp(264px, 27.03vw, 346px);
								}
							}
							.item4 {
								p {
									font-weight: 600;
									font-size: clamp(30px, 6.09vw, 78px);
									line-height: 100%;
									color: var(--color-black);
								}
							}
						}
					}
					.box_item-1 {
						.box_item_wrap {
							margin-bottom: clamp(12px, 1.5625vw, 20px);
						}
					}
					.box_item-2 {
						.box_item_wrap {
							margin: 20px 0;
							width: 100%;
							height: 2px;
							background-color: #e4e4e4;
						}
					}
					.box_item-3 {
						.box_item_wrap {
							margin-top: clamp(12px, 1.5625vw, 20px);
						}
					}
					#run_box3_item1 {
						transform: translateY(70px);
					}
					#run_box3_item2 {
						transform: translateY(80px);
					}
					#run_box3_item3 {
						transform: translateY(130px);
					}
				}
			}
			#run_box1_t {
				transform: translateY(150px);
				transition: transform 1.2s cubic-bezier(0.1, 0.69, 0.36, 0.98);
			}
			#run_box1_d {
				transform: translateY(80px);
				transition: transform 1.2s cubic-bezier(0.1, 0.69, 0.36, 0.98);
			}
			&__title {
				margin-top: clamp(64px, 9.375vw, 120px);
				width: 100%;
				h3 {
					font-weight: 600;
					font-size: clamp(40px, 6.09vw, 78px);
					line-height: 120%;
					span {
						mark {
							box-sizing: border-box;
							background-color: var(--color-orange);
							color: var(--color-white);
							padding: 0 0.1em;
						}
					}
				}
			}
			#run_box2 {
				transform: translateY(150px);
				transition: transform 1.2s cubic-bezier(0.1, 0.69, 0.36, 0.98);
				padding-bottom: 40px;
			}
			&__title_run {
				margin-top: calc(clamp(120px, 15.625vw, 200px) - 40px);
				position: relative;
				height: clamp(70px, 18.75vw, 240px);
				transition: transform 0.8s cubic-bezier(0.1, 0.69, 0.36, 0.98);
				.title_wrap {
					position: absolute;
					width: calc(
						clamp(1000px, 236.72vw, 3030px) +
							clamp(66px, 18.75vw, 240px)
					);
					right: calc(
						clamp(-450px, -236.72vw, -3030px) -
							clamp(66px, 18.75vw, 240px)
					);
					display: flex;
					flex-wrap: nowrap;
					justify-content: center;
					align-items: flex-start;
					img {
						margin-right: clamp(16px, 6.25vw, 80px);
						margin-top: clamp(4px, 1.875vw, 24px);
						transition: transform 0.8s
							cubic-bezier(0.1, 0.69, 0.36, 0.98);
						max-width: clamp(40px, 12.5vw, 160px);
					}
					h2 {
						font-weight: 600;
						font-size: clamp(66px, 18.75vw, 240px);
						line-height: 100%;
						width: clamp(1000px, 236.72vw, 3030px);
						white-space: nowrap;
					}
				}
			}
			&__title2 {
				margin-top: clamp(32px, 6.25vw, 80px);
				h3 {
					font-size: clamp(22px, 5.6vw, 78px);
					line-height: 120%;
				}
			}
		}
	}
}
.footer {
	background-color: var(--color-orange);
	width: 100%;
	.container {
		background-color: var(--color-orange);
		width: 100%;
		max-width: 1320px;
		margin: 0 auto;
		padding: 0 20px;
		padding-top: clamp(40px, 12.5vw, 160px);
		padding-bottom: clamp(40px, 6.25vw, 80px);
		.order {
			display: flex;
			align-items: center;
			justify-content: space-between;
			width: 100%;
			&__title {
				max-width: 840px;
				h2 {
					font-weight: 600;
					font-size: clamp(66px, 11.72vw, 150px);
					line-height: 100%;
					color: var(--color-white);
				}
			}
			.mob-order__calc {
				display: none;
			}
			&__calc {
				position: relative;
				display: block;
				color: transparent;
				text-decoration: none;
				max-width: clamp(120px, 20.32vw, 260px);
				width: 100%;
				margin-right: clamp(20px, 3.4375vw, 44px);
				cursor: pointer;
				.order-round {
					background-image: url('../../public/images/circle02.svg');
					background-position: center;
					background-size: 100%;
					background-repeat: no-repeat;
					min-width: clamp(120px, 20.32vw, 260px);
					min-height: clamp(120px, 20.32vw, 260px);
					transition: transform 0.8s cubic-bezier(0.4, 0.7, 0.3, 1);
				}
				.order-text {
					p {
						position: absolute;
						top: 50%;
						left: 50%;
						transform: translate(-50%, -50%);
						font-weight: 600;
						font-size: clamp(14px, 1.72vw, 22px);
						line-height: 127%;
						max-width: 132px;
						color: var(--color-black);
					}
				}
				.order-arrow {
					position: absolute;
					top: clamp(46%, 10.156vw, 50%);
					transform: translateY(
						calc(-50% + clamp(4px, 1.24vw, 16px))
					);
					right: clamp(-42px, -3.28vw, -20px);
					img {
						max-width: clamp(70px, 11.33vw, 145px);
					}
				}
			}
		}
		.info {
			margin-top: clamp(32px, 6.25vw, 80px);
			&__email {
				a {
					font-weight: 600;
					font-size: clamp(24px, 6.09vw, 78px);
					line-height: 120%;
					color: var(--color-white);
				}
			}
			&__phone {
				margin-top: clamp(24px, 3.125vw, 40px);
				a {
					font-weight: 600;
					font-size: clamp(30px, 6.09vw, 78px);
					line-height: 120%;
					color: var(--color-white);
				}
			}
			&__line {
				margin: clamp(32px, 6.25vw, 80px) 0 clamp(24px, 3.125vw, 40px) 0;
				width: 100%;
				height: 2px;
				background-color: rgba(255, 255, 255, 0.3);
			}
			&__bottom {
				display: flex;
				align-items: center;
				justify-content: space-between;
				&_item {
					font-weight: 600;
					font-size: clamp(14px, 1.72vw, 22px);
					line-height: 155%;
					color: var(--color-white);
				}
			}
		}
	}
}
@media (max-width: 1024px) {
	#customCursor {
		display: none;
	}
	.header {
		min-height: 64px;
		&_wrapper {
			min-height: 64px;
		}
		&__logo {
			display: none;
		}
		&__logo-mob {
			width: 80%;
			display: block;
			text-align: left;
			&_img {
				margin-top: 20px;
			}
		}
		&__menu {
			width: 20%;
			margin-top: 8px;
			&_nav {
				.nav_wrap {
					margin-top: 20px;
				}
				.logo {
					margin-left: 0px;
				}
			}
		}
	}
	.main {
		._1 {
			.intro {
				margin-top: 64px;
				&__logo_img {
					display: none;
				}
			}
		}
	}
}

@media (max-width: 768px) {
	.main {
		._1 {
			.intro {
				&__box {
					.calc {
						display: none;
					}
					.mob-calc {
						display: block;
						position: relative;
						max-width: clamp(120px, 20.32vw, 260px);
						width: 100%;
						transition: transform 1s
							cubic-bezier(0.1, 0.69, 0.36, 0.98);
						opacity: 1;
						cursor: pointer;
						z-index: 1000;
						.mob-calc-round {
							background: #ff862d;
							border-radius: 100%;
							min-width: clamp(120px, 20.32vw, 260px);
							min-height: clamp(120px, 20.32vw, 260px);
						}
						.mob-calc-text {
							position: absolute;
							top: 50%;
							left: 50%;
							transform: translate(-50%, -50%);
							p {
								font-weight: 600;
								font-size: clamp(14px, 1.72vw, 22px);
								line-height: 127%;
								max-width: 132px;
								color: var(--color-white);
							}
						}
					}
				}
			}
		}
	}
	.footer {
		.container {
			.order {
				.order__calc {
					display: none;
				}
				.mob-order__calc {
					display: block;
					position: relative;
					max-width: clamp(120px, 20.32vw, 260px);
					width: 100%;
					cursor: pointer;
					.mob-order-round {
						background: var(--color-white);
						border-radius: 100%;
						min-width: clamp(120px, 20.32vw, 260px);
						min-height: clamp(120px, 20.32vw, 260px);
					}
					.mob-order-text {
						position: absolute;
						top: 50%;
						left: 50%;
						transform: translate(-50%, -50%);
						p {
							font-weight: 600;
							font-size: clamp(14px, 1.72vw, 22px);
							line-height: 127%;
							max-width: 132px;
							color: var(--color-black);
						}
					}
				}
			}
		}
	}
}
@media (max-width: 430px) {
	.main {
		._1 {
			.intro {
				&__mob-subtitle {
					display: block;
					margin-top: 32px;
					max-width: 270px;
					p {
						font-weight: 600;
						font-size: 14px;
						line-height: 171%;
					}
				}
			}
		}
		._2 {
			.team {
				&__box {
					&_item {
						.expandable_box {
							.wrapper {
								.marquee_body {
									.marquee,
									.marquee_reverse {
										margin-bottom: 12px;
										&:last-child {
											margin-bottom: 0;
										}
										.marquee_inner {
											.service_keywords {
												li {
													line-height: 100%;
													margin: 0
														clamp(
															6px,
															1.5625vw,
															20px
														);
												}
											}
										}
									}
								}
							}
						}
					}
				}
			}
		}
		._3 {
			.services {
				&__box {
					&_wrapper {
						.box_item-1 {
							.box_item_wrap {
								flex-direction: column;
								align-items: flex-start;
								.item1 {
									margin-bottom: 12px;
								}
							}
						}
						.box_item-2 {
							.box_item_wrap {
								margin: 12px 0;
							}
						}
						.box_item-3 {
							.box_item_wrap {
								flex-direction: column;
								align-items: flex-start;
								.item3 {
									margin-bottom: 12px;
								}
							}
						}
					}
				}
			}
		}
	}
	.footer {
		.container {
			.order {
				flex-direction: column;
				justify-content: flex-start;
				align-items: flex-start;
				&__title {
					margin-bottom: 30px;
				}
				.mob-order__calc {
					align-self: flex-end;
				}
			}
			.info {
				&__line {
					margin: 24px 0;
				}
				&__bottom {
					flex-direction: column;
					&_item {
						margin-bottom: 10px;
						&:last-child {
							margin-bottom: 0;
						}
					}
				}
			}
		}
	}
}
@media (max-width: 375px) {
	.main {
		._1 {
			.intro {
				&__box {
					.description {
						margin-right: 0;
					}
					.mob-calc {
						max-width: 100px;
						.mob-calc-round {
							max-width: 100px;
							min-width: 100px;
							min-height: 100px;
						}
						.mob-calc-text {
							p {
								font-size: 13px;
								line-height: 120%;
							}
						}
					}
				}
				&__mob-subtitle {
					max-width: 200px;
				}
			}
		}
	}
	.footer {
		.container {
			.order {
				.mob-order__calc {
					max-width: 100px;
					.mob-order-round {
						max-width: 100px;
						min-width: 100px;
						min-height: 100px;
					}
					.mob-order-text {
						p {
							font-size: 13px;
							line-height: 120%;
						}
					}
				}
			}
		}
	}
}
